import React from 'react'

function Footer() {
  return (
    <div>
      <footer className="bg-white mt-5 shadow">
        <div className="container pt-5">
          <div className="row py-4">
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <p className="font-italic text-muted">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
          
            </div>
            <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
              <h6 className="text-uppercase font-weight-bold mb-4">Shop</h6>
              <ul className="list-unstyled mb-0">
                <li className="mb-2">
                  <a href="#" className="text-muted">
                    For Women
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="text-muted">
                    For Men
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="text-muted">
                    Stores
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="text-muted">
                    Our Blog
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
              <h6 className="text-uppercase font-weight-bold mb-4">Company</h6>
              <ul className="list-unstyled mb-0">
                <li className="mb-2">
                  <a href="#" className="text-muted">
                    Login
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="text-muted">
                    Register
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="text-muted">
                    Wishlist
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="text-muted">
                    Our Products
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 mb-lg-0">
              <h6 className="text-uppercase font-weight-bold mb-4">Newsletter</h6>
              <p className="text-muted mb-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. At
                itaque temporibus.
              </p>
              <div className="p-1 rounded border">
                <div className="input-group">
                  <div className="input-group-append">
                    <button
                      id="button-addon1"
                      type="submit"
                      className="btn btn-link"
                    >
                      <i className="fa fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import API_BASE_URL from "../config";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import googleIcon from "../assets/googleIcon/icon.svg";
import "../pages/css/widgetRenderer.css";
import { Spinner } from "react-bootstrap";

function WidgetCopy() {
  const { place_id_token } = useParams();
  const [place_id, setPlaceId] = useState();
  const [reviewsData, setReviews] = useState([]);
  const [message, setMessage] = useState("");
  const [widgetId, setWidgetId] = useState();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState();
  const widgetData = useSelector((state) => state?.layout?.layout) || [];

  // console.log(widgetId)
  // console.log(place_id)

  useEffect(() => {
    const getOriginalPlaceIdFromToken = async () => {
      try {
        const getPlaceId = await fetch(`${API_BASE_URL}/getPlace_id/${place_id_token}`);
        if (!getPlaceId.ok) {
          throw new Error(
            `Failed to fetch place ID with status ${getPlaceId.status}`
          );
        }

        const responseData = await getPlaceId.json();
        setPlaceId(responseData.place_id);
        setType(responseData.layout.type);
        // console.log(responseData.place_id);
        const parseIntWidgetId = parseInt(responseData.widget_id, 10);
        setWidgetId(parseIntWidgetId);

      } catch (error) {
        console.error("Error fetching original place ID:", error);
      }
    };
    getOriginalPlaceIdFromToken();
  }, [place_id_token, widgetData]);

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      const getAllReviews = await fetch(`${API_BASE_URL}/getReviewsByCheckingWidgetAndPlaceId/${widgetId}`);
      const getAllReviesJson = await getAllReviews.json();
      setReviews(getAllReviesJson);
      setLoading(false);
      // console.log(getAllReviesJson);
    };
    getAllData();
  }, [widgetId, place_id]);



  

  let reviews = [];

  if (reviewsData.result && reviewsData.result.reviews) {
    // Case 1: Reviews are nested under "result"
    reviews = reviewsData.result.reviews;
  } else if (
    reviewsData.Facebook &&
    reviewsData.Facebook.data &&
    reviewsData.Google
  ) {
    // Case 2: Reviews are under "Facebook" object

    const facebookReviews = reviewsData.Facebook
      ? reviewsData.Facebook.data
      : [];
    const googleReviews = reviewsData.Google ? reviewsData.Google : [];

    // Combining Facebook and Google reviews into a single array
    reviews = [...facebookReviews, ...googleReviews];
  }

  const averageRating = useMemo(() => {
    if (reviews?.length > 0) {
      const totalRating = reviews.reduce((sum, review) => {
        if ("rating" in review) {
          // Check if 'rating' property exists in the review
          return sum + review.rating;
        } else if (review.hasOwnProperty("rating")) {
          // Handle cases where 'rating' is explicitly set to null or undefined
          return sum; // Exclude such reviews from the total
        } else {
          // Handle cases where 'rating' property doesn't exist
          return sum + 5; // Assume a default rating of 5 if not provided
        }
      }, 0);
      return totalRating / reviews.length;
    }
    return 0;
  }, [reviews]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const calculateTotalReviews = () => {
    return reviews.length;
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp); // Multiply by 1000 to convert seconds to milliseconds
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleString("en-US", options);
  };

  const renderStars = (rating) => {
    const roundedRating = Math.round(rating); // Round the rating to the nearest whole number

    const stars = [];
    for (let i = 0; i < roundedRating; i++) {
      stars.push(
        <span key={i} className="star">
          &#9733;
        </span>
      );
    }

    for (let i = 0; i < 5 - roundedRating; i++) {
      stars.push(
        <span key={`empty-${i}`} className="star">
          &#9734;
        </span>
      );
    }

    return stars;
  };

  const getRatingText = (rating) => {
    if (rating >= 4.5) {
      return "Excellent";
    } else if (rating >= 4) {
      return "Very Good";
    } else if (rating >= 3) {
      return "Good";
    } else {
      return "Average";
    }
  };

  if (loading) {
    return (
      <div className="container loading-spinner-container">
        <Spinner /> {/* Replace with your actual loading spinner component */}
      </div>
    );
  }

  return (
    <div className="container" style={{ overflow: "hidden" }}>
      {type === "button" && (
        <div className="mt-5">
          <h2>Reviews</h2>
          <div className="review-stats">
            <p>{getRatingText(averageRating)}</p>
            <div className="rating-stars">{renderStars(averageRating)}</div>
            <p>
              {reviews?.length} <strong>reviews on </strong>
            </p>

            {reviews[0]?.recommendation_type ? (
              <div>All plateforms</div>
            ) : (
              <img
                src={googleIcon}
                alt="Google Icon"
                className="google-icon"
                height="20px"
              />
            )}
          </div>

          {message && <div className="alert alert-success mt-3">{message}</div>}
        </div>
      )}

      {type === "slider" && (
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="mt-5">
            <h2>Reviews</h2>
            <Slider {...sliderSettings}>
              {reviews?.map((review, index) => (
                <div key={index} className="review-slide reviewInner">
                  <div className="review-header">
                    <h3>{review?.author_name}</h3>
                    {review?.recommendation_type ? (
                      <p>{review?.recommendation_type}</p>
                    ) : (
                      <img
                        src={review?.profile_photo_url || ""}
                        alt={`Profile Photo of ${review?.author_name}`}
                        className="user-photo"
                        loading="lazy"
                      />
                    )}
                  </div>
                  <p className="review-date">
                    <strong>Posted on:</strong>{" "}
                    {formatTimestamp(
                      review?.created_time ? review?.created_time : review?.time
                    )}
                  </p>
                  <div className="rating-stars">
                    {renderStars(review?.rating)}
                  </div>
                  {review?.recommendation_type ? (
                    <i className="fab fa-facebook facebook-icon"></i>
                  ) : (
                    <img
                      src={googleIcon}
                      alt="Google Icon"
                      className="google-icon"
                      height="20px"
                    />
                  )}
                  <div className="reviewContent">
                    <p>{review?.text ? review?.text : review?.review_text}</p>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="mt-3">
              <p>
                <strong>Average Rating:</strong> {averageRating?.toFixed(1)} of
                5 based on
                {calculateTotalReviews()} reviews
              </p>
            </div>

            {message && (
              <div className="alert alert-success mt-3">{message}</div>
            )}
          </div>
        </div>
      )}

      {type === "singlePage" && (
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="mt-5">
            <h2>Reviews</h2>
            {reviews?.map((review, index) => (
              <div key={index} className="reviewInner">
                <div className="review-header">
                  <h3>{review?.author_name}</h3>
                  {review?.recommendation_type ? (
                    <p>{review?.recommendation_type}</p>
                  ) : (
                    <img
                      src={review?.profile_photo_url || ""}
                      alt={`Profile Photo of ${review?.author_name}`}
                      className="user-photo"
                      loading="lazy"
                    />
                  )}
                </div>
                <p className="review-date">
                  <strong>Posted on:</strong>{" "}
                  {formatTimestamp(
                    review?.created_time ? review?.created_time : review?.time
                  )}
                </p>
                <div className="rating-stars">
                  {renderStars(review?.rating)}
                </div>
                {review?.recommendation_type ? (
                  <i className="fab fa-facebook facebook-icon"></i>
                ) : (
                  <img
                    src={googleIcon}
                    alt="Google Icon"
                    className="google-icon"
                    height="20px"
                  />
                )}
                <div className="reviewContent">
                  <p>{review?.text ? review?.text : review?.review_text}</p>
                </div>
              </div>
            ))}
            <div className="mt-3">
              <p>
                <strong>Average Rating:</strong> {averageRating?.toFixed(1)} of
                5 based on {calculateTotalReviews()} reviews
              </p>
            </div>

            {message && (
              <div className="alert alert-success mt-3">{message}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default WidgetCopy;

import React, { useEffect, useState } from "react";
import API_BASE_URL from "../config";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setAccessToken } from "../redux/accessTokenSlice"; 
import { connect } from "formik";
import { Modal, Button } from "react-bootstrap"; 
import { useNavigate } from "react-router-dom";
import { setLayoutDetails } from "../redux/layoutSlice";

function FacebookConnect() {
  const [userId, setUserId] = useState();
  const [facebookData, setFacebookData] = useState([]);
  const dispatch = useDispatch();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.auth.user);
  const facebookToken = useSelector((state)=> state.saveAccessToken.accessToken);
  // console.log(facebookToken);
  // Extracting user ID from userDetails
  const userId1 = userDetails?.data?.user?.id || userDetails?.user?.id;

  useEffect(() => {
    setUserId(userId1); 
  }, [userId1]);

  console.log('User ID:', userId);

  const handleMessage = async (event) => {
    const data = event.data;
    console.log("Received data from popup window:", data);
    const access_token = data.access_token;
    const page_id = data.page_id;
    const name = data.name;

    dispatch(setAccessToken({ accessToken: access_token, pageId: page_id }));


    
    try {
      const response = await axios.get(`${API_BASE_URL}/savetoken`, {
        params: {
          userId1: userId1,
          access_token: access_token,
          pageId: page_id,
          name: name
        }
      });

      console.log("Response from API:", response.data);

      if(response.data.success){
        setShowSuccessModal(true); // Show success modal
        dispatch(setLayoutDetails(response.data.widgets));
      }

      
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors
    }

    window.removeEventListener("message", handleMessage);
  };

  const handleClick = () => {
    // Constructing the URL with the user ID as a query parameter
    const connectUrl = `${API_BASE_URL}/auth`;
    window.open(connectUrl, "_blank", "width=600,height=800");
    window.addEventListener("message", handleMessage);
  };


  const handleCloseModal = () => {
    navigate(`/page/dashboard`);
  };



  return (
   <div>
      <h6>New platform</h6>
      <p>Set up the source of your reviews on Facebook</p>
      <p>Type: Facebook</p>
      <p>
        Page: Please connect Facebook to get a list of your Facebook Pages. As
        you will see, we require permissions only to list your Facebook Pages.
        Nothing more or less.
      </p>
      <button onClick={handleClick}>Connect</button>

      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}> {/* Success modal */}
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are now connected to Facebook</Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FacebookConnect;

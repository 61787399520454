import React, { useCallback, useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { Trash } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

import "../pages/css/list.css";

import API_BASE_URL from "../config";
import { clearPlace } from "../redux/placeSlice";
import { clearAccessToken } from "../redux/accessTokenSlice";
import { clearLayoutDetails, setLayoutDetails } from "../redux/layoutSlice";
import toast from "react-hot-toast";

const List = () => {
  const token = new Cookies().get("token");
  const dispatch = useDispatch();
  const placeDetails = useSelector((state) => state.place.place);
  const userDetails = useSelector((state) => state.auth.user);
  const facebookDetails = useSelector((state) => state.saveAccessToken);
  const [googleReviewsInfo, setGoogleReviewsInfo] = useState({});
  const [facebookReviewsInfo, setFacebookReviewsInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [listState, setListState] = useState({
    list: [],
  });

  // console.log(placeDetails);

  const fetchDataAndReviews = useCallback(async () => {
    try {
      const userId = userDetails?.user?.id || userDetails?.data?.user?.id;
      const listDataResponse = await fetch(`${API_BASE_URL}/list/${userId}`);
      const listData = (await listDataResponse.json()).data || [];
      setListState({ list: listData });

      if (placeDetails?.platform === "Google") {
        const reviewData = await fetchReviews(placeDetails.place_id);
        setGoogleReviewsInfo(getReviewsInfo(reviewData));
      }

      if (facebookDetails?.accessToken) {
        const reviewData = await fetchFacebookReviews(
          facebookDetails.pageId,
          facebookDetails.accessToken
        );
        setFacebookReviewsInfo(getFacebookReviewsInfo(reviewData));
      }

      // Set loading to false after fetching data and reviews
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data and reviews:", error);
    }
  }, [userDetails, placeDetails, facebookDetails]);

  const fetchReviews = async (placeId) => {
    const reviewResponse = await fetch(`${API_BASE_URL}/get/${placeId}`);
    return reviewResponse.json();
  };

  const fetchFacebookReviews = async (pageId, accessToken) => {
    const requestBody = {
      page_id: pageId,
      access_token: accessToken,
    };

    const reviewResponse = await fetch(`${API_BASE_URL}/getFacebookReviews`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    return reviewResponse.json();
  };

  const getReviewsInfo = (reviewsData) => {
    if (reviewsData?.result?.reviews) {
      const reviews = reviewsData.result.reviews;
      const numberOfReviews = reviews.length;
      const totalRating = reviews.reduce(
        (sum, review) => sum + review.rating,
        0
      );
      const averageRating =
        numberOfReviews > 0 ? totalRating / numberOfReviews : 0;
      return { totalReviews: numberOfReviews, averageRating: averageRating };
    }
    return { totalReviews: 0, averageRating: 0 };
  };

  const getFacebookReviewsInfo = (reviewsData) => {
    if (reviewsData && reviewsData.data) {
      const reviews = reviewsData.data;
      const numberOfReviews = reviews.length;
      let totalRating = 0;

      for (const review of reviews) {
        totalRating +=
          review.recommendation_type === "positive" ? 1 : 0;
      }

      const averageRating =
        numberOfReviews > 0 ? (totalRating * 5) / numberOfReviews : 0;

      return { totalReviews: numberOfReviews, averageRating: averageRating };
    }

    return { totalReviews: 0, averageRating: 0 };
  };

  useEffect(() => {
    fetchDataAndReviews();
  }, [fetchDataAndReviews]);

  const handleDelete = async (itemId, plateform) => {
    try {
      
      const response = await fetch(`${API_BASE_URL}/delete/${itemId}/${plateform}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const res = await response.json();
      if (res.platform === "Google") {
        dispatch(clearPlace());
        dispatch(setLayoutDetails(res.widgetData));
        toast.success('Google platform delete successfully');
      }
      if (res.platform === "Facebook") {
        dispatch(clearAccessToken());
        dispatch(setLayoutDetails(res.widgetData));
        toast.success('Facebook platform delete successfully');
      }

      if (response.ok) {
        const updatedList = listState.list.filter((item) => item.id !== itemId);
        setListState({ list: updatedList });
      } else {
        console.error("Failed to delete item.");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  if (loading) {
    return (
      <div className="container loading-spinner-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="mt-5">
        <div>
          {listState.list.length > 0 && <h2>List</h2>}
          <ul className="list-group">
            {listState.list.map((item) => (
              <div key={item.id}>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <span>{item.platform}</span>
                  <div className="d-flex flex-column list_paragraph">
                    <h6>{item.address}</h6>
                    <p className="mb-0">
                      {item.platform === "Google"
                        ? googleReviewsInfo?.averageRating?.toFixed(1)
                        : item.platform === "Facebook"
                        ? facebookReviewsInfo?.averageRating?.toFixed(1)
                        : "N/A"}{" "}
                      of 5 based on{" "}
                      {item.platform === "Google"
                        ? googleReviewsInfo.totalReviews
                        : item.platform === "Facebook"
                        ? facebookReviewsInfo.totalReviews
                        : 0}{" "}
                      reviews
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-center text-center">
                    <p className="mb-0">
                      {item.platform === "Google"
                        ? googleReviewsInfo.totalReviews
                        : item.platform === "Facebook"
                        ? facebookReviewsInfo.totalReviews
                        : 0}
                    </p>
                    <p>reviews</p>
                  </div>
                  <span className="badge rounded-pill">
                    <Trash
                      onClick={() => handleDelete(item.id, item.platform)}
                      color="red"
                    />
                  </span>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default List;

// config.js



 const API_BASE_URL = 'https://api.recommendas.com/public/api';
export const APP_BASE_URL = 'https://recommendas.com';
export default API_BASE_URL;



//  const API_BASE_URL = 'http://localhost:8000/api';
// export const APP_BASE_URL = 'http://localhost:3000';
// export default API_BASE_URL;


 
//  const API_BASE_URL = 'http://localhost:8000/api';
// export const APP_BASE_URL = 'http://localhost:3000';
// export default API_BASE_URL;

// export const API_BASE_URL = 'https://d645-223-178-208-126.ngrok-free.app/api';
// export const APP_BASE_URL = 'https://f2ce-223-178-208-126.ngrok-free.app';
 

 
import logo from './logo.svg';
import './App.css';
import Widget from './components/ConnectedPlateforms';
import RouteFile from './routeFiles/RouteFile';
              
function App() {
  return (
    <div>
     <RouteFile/>
    </div>
  );
}

export default App;

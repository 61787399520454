import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Cookies } from 'react-cookie';
import API_BASE_URL from "../config";

// Create async thunk for user registration
export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (userDetails) => {
    const cookies = new Cookies();
    try {
      const response = await axios.post(`${API_BASE_URL}/register`, userDetails);
      
      const authToken = response.data.access_token;
      localStorage.setItem('user', JSON.stringify(response));
     
      cookies.set('token', authToken, { maxAge: 3600 });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Create user slice
const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    user: null,
    error: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        if (action.error.message === 'Request failed with status code 401') {
          state.error = 'Access Denied! Invalid Credentials';
        } else if(action.error.message==='Request failed with status code 422'){
            state.error = 'Email already exit';
        } 
        
        else {
          state.error = action.error.message;
        }
      });
  }
});

// Export the user reducer
export default userSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../config";
import { setLayoutDetails } from "../redux/layoutSlice";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

function Widget() {
  const [placeData, setPlaceData] = useState(null);
  const data = useSelector((state) => state.place?.place);
  const layout = useSelector((state) => state.layout.layout);

  const [List1, setList1] = useState([]);
  const [loading, setLoading] = useState(false);
  const widgetData = useSelector((state) => state.layout.layout) || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (data?.place_id) {
        setPlaceData({ place_id: data.place_id, platform: data.platform });
      }
    };
    fetchData();
  }, [data]);

  useEffect(() => {
    if (!List1.length && (widgetData.length || placeData)) {
      setLoading(true);
      const updatedList1 = widgetData?.map((item, index) => ({
        id: item?.id,
        placeId: placeData ? placeData.encodedPlaceId : "",
        name: `Widget ${index + 1}`,
        source: item.source ? item?.source : "alddl",
      }));
      setList1(updatedList1);
      setLoading(false);
    }
  }, [widgetData, placeData]);

  const handleButtonClick = (id) => {
    navigate(`/page/widget-customization/${id}`);
  };

  if (!placeData && !widgetData) {
    return null; // or render a loading state
  }

  const handleWidgetClick = () => {
    navigate("/page/add-widget");
  };

  const handleDelete = async (id) => {
    const deleteWidgetData = await fetch(`${API_BASE_URL}/deleteWidget/${id}`);
    const jsonData = await deleteWidgetData.json();
    dispatch(setLayoutDetails(jsonData.data));
    setList1(jsonData.data);
    toast.success("Successfully deleted!");
    // successNotification();
  };

  if (loading) {
    return (
      <div className="container loading-spinner-container">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="container">
      <div></div>
      <button className="btn btn-primary" onClick={handleWidgetClick}>
        ADD
      </button>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Source</th>
            <th>Layout</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
   
          {List1?.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.name ? item.name : "Widget " + (index + 1)}</td>
              <td>{item.source}</td>
              <td>
                {layout.find((layoutItem) => layoutItem.id === item.id)?.layout
                  ?.type || "Unknown"}
              </td>
              <td>
                <button
                  className="btn btn-primary"
                  onClick={() => handleButtonClick(item.id)}
                >
                  Edit Widget
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(item.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Widget;

// src/components/FileViewer.jsx
import React from 'react';

const Loader = () => {
  const codeContent = `
    var a = 10;
    var b = 34;
    var c = a + b;
    console.log(c);
  `;

  return (
    <div>
      <h2>JavaScript Code:</h2>
      <script dangerouslySetInnerHTML={{ __html: codeContent }} />
    </div>
  );
}

export default Loader;

import { createSlice } from "@reduxjs/toolkit";

const layoutSlice = createSlice({
    name : 'layout',
    initialState:{
        layout:null
    },
    reducers:{
        setLayoutDetails: (state,action) =>{
            console.log('Setting layout details:', action.payload);
                state.layout = action.payload;
        },
        clearLayoutDetails: (state) =>{
            console.log('clear layout details');
            state.layout=null;
        },
    }
});

 export const {setLayoutDetails,clearLayoutDetails} = layoutSlice.actions;

 export default layoutSlice.reducer;
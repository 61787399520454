import React, { useEffect, useState, useRef } from "react";
import API_BASE_URL, { APP_BASE_URL } from "../config";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function RegisterPage() {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pageRegistrations, setPageRegistrations] = useState([]);
  const userDetails = useSelector((state) => state.auth.user);
  const user_id = userDetails?.user?.id || userDetails?.data?.user?.id;

  const inputRef = useRef(null); // Create a ref for the input field

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = async () => {
    try {
      if (!type || !name) {
        toast.error("Please fill in all details");
        return;
      }

      const response = await fetch(
        `${API_BASE_URL}/registerPage/${type}/${name}/${user_id}`
      );
      const data = await response.json();
      if (response.ok) {
        if (data.message === 'User already has a page registration for this type.') {
          toast.error(data.message);
        } else if (data.success) {
          toast.success('Page added successfully');
        }

        fetchList(); // Fetch the updated list after successful save
        setName("");
        setType("");
      } else {
        throw new Error("Failed to register page: " + data.message);
      }
    } catch (error) {
      setErrorMessage("Error registering page: " + error.message);
      setSuccessMessage("");
    }
  };

  const fetchList = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/getAllList/${user_id}`);
      const data = await response.json();
      if (response.ok) {
        setPageRegistrations(data.data); // Set page registrations array
      } else {
        throw new Error("Failed to fetch page registrations: " + data.message);
      }
    } catch (error) {
      console.error("Error fetching page registrations:", error);
    }
  };

  useEffect(() => {
    fetchList();
  }, [user_id]);

  const handleCopyButton = (name, type) => {
    const buttonIframe = type === 'company' ? `${APP_BASE_URL}/company/${name}` : `${APP_BASE_URL}/${name}`;
    const tempInput = document.createElement("input");
    tempInput.value = buttonIframe;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    toast.success('Link copied to clipboard!', {
      style: {
        border: '1px solid #713200',
        padding: '16px',
        color: '#713200',
      },
      iconTheme: {
        primary: '#713200',
        secondary: '#FFFAEE',
      },
    });
  };

  const handleDeleteButton = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/deletepage/${id}`);
      const data = await response.json();
      if (response.ok) {
        setPageRegistrations(data.data);
        toast.success('Page deleted successfully');
      } else {
        throw new Error("Failed to delete page: " + data.message);
      }
    } catch (error) {
      console.error("Error deleting page:", error);
    }
  };

  const availableTypes = ['company', 'people'].filter(type => !pageRegistrations.some(registration => registration.type === type));

  return (
    <div>
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <label htmlFor="selectType">Select Type:</label>
      <select id="selectType" value={type} onChange={handleTypeChange}>
        <option>Select the value</option>
        {availableTypes.map((type, index) => (
          <option key={index} value={type}>
            {type === 'compadny' ? '' : type}
          </option>
        ))}
      </select>

      {type && (
        <div id={`${type}Input`}>
          <label htmlFor={type}>{type === 'company' ? 'Company' : 'People'}:</label>
          <input
            ref={inputRef}
            type="text"
            id={type}
            name={type}
            placeholder={`Enter ${type === 'company' ? 'company name' : "people's names"}`}
            value={name}
            onChange={handleNameChange}
          />
        </div>
      )}

      <button className="btn btn-primary" onClick={handleSave}>
        Save
      </button>

      <div>
        <h2>Page Registrations</h2>
        <ul>
          {pageRegistrations.map((registration) => (
            <li key={registration.id}>
              <div>ID: {registration.id}</div>
              <div>User ID: {registration.user_id}</div>
              <div>Type: {registration.type}</div>
              <div>Name: {registration.name}</div>

              <button
                onClick={() => handleCopyButton(registration.name, registration.type)}
                className="btn btn-primary"
              >
                copy to clipboard
              </button>
              <button
                onClick={() => handleDeleteButton(registration.id)}
                className="btn btn-danger"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default RegisterPage;

// store.js

import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../redux/authSlice';
import refreshReducer from '../redux/refreshSlice';
import register from '../redux/register'
import place from '../redux/placeSlice';
import layoutSlice from '../redux/layoutSlice';
import saveAccessToken from '../redux/accessTokenSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    place: place,
    register: register,
    refresh: refreshReducer,
    layout:layoutSlice,
    saveAccessToken:saveAccessToken
    // Add other reducers if needed
  },
});


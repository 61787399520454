import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import API_BASE_URL from "../config";

const ShowReview = () => {

  const { place_id } = useParams();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewResponse = await fetch(`${API_BASE_URL}/get/${place_id}`);
        const reviewsData = await reviewResponse.json();
        // console.log(reviewsData);
        setReviews(reviewsData?.result.reviews); // Assuming reviews are under reviews key in the response
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, [place_id]);

  if (!reviews || reviews.length === 0) {
    return (
      <div>
        <div className="container">
          {" "}
          <div className="mt-4">No reviews available.</div>
          <div>
            {" "}
            <Link to="/">Go to Home</Link>
          </div>
        </div>
      </div>
    );
  }

  // Calculate the average rating
  const averageRating =
    reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length;

  return (
    <div className="container">
      <div className="mt-5">
        <h2>Reviews</h2>
        <div>
          <p>
            <strong>Average Rating:</strong> {averageRating?.toFixed(1)} of 5
            based on {reviews?.length} reviews
          </p>
        </div>
        <div>
          {" "}
          <Link to="/page/dashboard">Go to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default ShowReview;

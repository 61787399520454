import React from 'react'
import { useNavigate } from "react-router-dom";

function Index() {
    const navigate = useNavigate();
    const handleClick = () =>{
            navigate('/login');
    }
    
  return (
    <div>
        <h1>Welcome, This is Home page</h1>
        <button onClick={handleClick} >Please Login first</button>
    </div>
  )
}

export default Index
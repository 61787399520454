import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import "../pages/css/autocompletecomponent.css";
import API_BASE_URL from "../config";
import { useSelector, useDispatch } from "react-redux";
import { setPlaceDetails } from "../redux/placeSlice";
import { setLayoutDetails } from "../redux/layoutSlice";
import toast from "react-hot-toast";

function AutocompleteComponent() {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.auth.user);
  const userId = userDetails?.data?.user?.id || userDetails?.user?.id;
  console.log(userId);

  const handleSelect = async (address) => {
    setValue(address, false);
    try {
      const results = await geocodeByAddress(address);
      // console.log(results[0]);
      const placeId = results[0].place_id;
      const photoRef = await fetch(`${API_BASE_URL}/photoRefrence/${placeId}`);
      const dataPhotoRef = await photoRef.json();
      console.log(dataPhotoRef);
      const photoRefrence = dataPhotoRef?.result?.photos[0]?.photo_reference;
      console.log(photoRefrence);
      const locationImg = await fetch(`${API_BASE_URL}/photo/${photoRefrence}`);

      setSelectedPlace({
        imgSource: locationImg.url,
        place_id: placeId,
        fullAddress: address,
        user_id: userId,
      });
      // console.log(selectedPlace);
    } catch (error) {
      console.log("Error selecting place:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Check if selectedPlace is already populated with necessary properties
      if (selectedPlace && selectedPlace.place_id && selectedPlace.imgSource) {
        const response = await fetch(`${API_BASE_URL}/places`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedPlace),
        });

        if (response.ok) {
          navigate(`/page/search/review/${selectedPlace.place_id}`);
          const responseJson = await response.json();
          // console.log(responseJson);
          dispatch(setPlaceDetails(responseJson.place));
          dispatch(setLayoutDetails(responseJson.widgets));
          toast.success('success');
          // window.location.reload();
        } else {
          console.error("Failed to save place ID.");
        }
      } else {
        console.error("Missing required properties in selectedPlace.");
      }
    } catch (error) {
      console.error("Error saving place ID:", error);
    }
  };

  return (
    <div className="container">
      <div className="mt-5">Google Business Profile name or location</div>
      <PlacesAutocomplete
        value={value}
        onChange={(newValue) => setValue(newValue)}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Search places...",
              })}
              className="w-100 form-control mt-3"
            />
            <div>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion)}
                >
                  <ul className="list-group">
                    <li className="list-group-item">
                      <span className="input_item">
                        {" "}
                        {suggestion.description}
                      </span>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      {/* Optionally, show additional information or trigger further actions */}

      {selectedPlace && (
        <div>
          <p>
            Source:
            <img
              src={selectedPlace?.imgSource}
              alt=""
              width="40"
              height="40"
            ></img>{" "}
            {value}
          </p>
          <button onClick={handleSubmit} className="btn btn-primary mt-3">
            Submit
          </button>
        </div>
      )}
    </div>
  );
}

export default AutocompleteComponent;

import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import API_BASE_URL from '../config';
import axios from 'axios';
import googleIcon from "../assets/googleIcon/icon.svg";
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';

function CompanyReviews() {
    const { companyName } = useParams();
    const [reviews, setReviews] = useState([]);
    const [selectedStars, setSelectedStars] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchReviews = async () => {
            try {
              setLoading(true);
                const response = await axios.get(`${API_BASE_URL}/pageReviews`, {
                    params: {
                        companyName: companyName
                    }
                });
              
                if (response) {
                    const facebookReviews = response.data[1][''].facebook?.original?.data;
                    const googleReviews = response.data[1][''].google?.original?.result?.reviews;
                    // Merging both sets of reviews
                    const allReviews = [...(facebookReviews || []), ...(googleReviews || [])];
                    // Setting the merged reviews to the state
                    console.log(allReviews,'fdfdfd')
                    setReviews(allReviews);
                    setLoading(false);
                } else {
                  setLoading(false);
                    console.log('Error fetching reviews:', response.status);
                }
            } catch (error) {
                console.log('Error fetching reviews:', error);
                toast.error('Company not found');
                setLoading(false);
              
            }
        };
        fetchReviews();
    }, [companyName]);
    
    const handleCheckboxChange = (star) => {
    
      const updatedSelectedStars = selectedStars.includes(star)
        ? selectedStars.filter((selectedStar) => selectedStar !== star)
        : [...selectedStars, star];
  
      setSelectedStars(updatedSelectedStars);
      // console.log(selectedStars)
    };

    const filteredReviews = (reviews || []).filter((review) =>
    selectedStars?.length === 0
      ? true
      : selectedStars.includes(review.rating)
  );

    const calculateTotalReviews = () => {
        return reviews.length;
      };
    
      const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp); // Multiply by 1000 to convert seconds to milliseconds
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleString("en-US", options);
      };
    
      const renderStars = (rating) => {
        const roundedRating = Math.round(rating); // Round the rating to the nearest whole number
    
        const stars = [];
        for (let i = 0; i < roundedRating; i++) {
          stars.push(
            <span key={i} className="star">
              &#9733;
            </span>
          );
        }
    
        for (let i = 0; i < 5 - roundedRating; i++) {
          stars.push(
            <span key={`empty-${i}`} className="star">
              &#9734;
            </span>
          );
        }
    
        return stars;
      };
    
      const averageRating = useMemo(() => {
        if (reviews?.length > 0) {
          const totalRating = reviews.reduce((sum, review) => {
            if ("rating" in review) {
              // Check if 'rating' property exists in the review
              return sum + review.rating;
            } else if (review.hasOwnProperty("rating")) {
              // Handle cases where 'rating' is explicitly set to null or undefined
              return sum; // Exclude such reviews from the total
            } else {
              // Handle cases where 'rating' property doesn't exist
              return sum + 5; // Assume a default rating of 5 if not provided
            }
          }, 0);
          return totalRating / reviews.length;
        }
        return 0;
      }, [reviews]);


      if (loading) {
        return (
          <div className="container loading-spinner-container">
            <Spinner /> {/* Replace with your actual loading spinner component */}
          </div>
        );
      }


    return (
        <div>
           
           <div className="container" style={{ overflow: "hidden" }}>
          <div className="mt-5">
            <h2>Reviews</h2>
            <div>
            {[1, 2, 3, 4, 5].map((star) => (
              <label key={star} className="star-checkbox-label">
                <input
                  type="checkbox"
                  value={star}
                  checked={selectedStars.includes(star)}
                  onChange={() => handleCheckboxChange(star)}
                />
                {star} Star
              </label>
            ))}
          </div>
            {filteredReviews?.map((review, index) => (
              <div key={index} className="reviewInner">
                <div className="review-header">
                  <h3>{review?.author_name}</h3>
                  {review?.recommendation_type ? (
                    <p>{review?.recommendation_type}</p>
                  ) : (
                    <img
                      src={review?.profile_photo_url || ""}
                      alt={`Profile Photo of ${review?.author_name}`}
                      className="user-photo"
                      loading="lazy"
                    />
                  )}
                </div>
                <p className="review-date">
                  <strong>Posted on:</strong>{" "}
                  {formatTimestamp(
                    review?.created_time ? review?.created_time : review?.time
                  )}
                </p>
                <div className="rating-stars">
                  {renderStars(review?.rating)}
                </div>
                {review?.recommendation_type ? (
                  <i className="fab fa-facebook facebook-icon"></i>
                ) : (
                  <img
                    src={googleIcon}
                    alt="Google Icon"
                    className="google-icon"
                    height="20px"
                  />
                )}
                <div className="reviewContent">
                  <p>{review?.text ? review?.text : review?.review_text}</p>
                </div>
              </div>
            ))}
            <div className="mt-3">
              <p>
                <strong>Average Rating:</strong> {averageRating?.toFixed(1)} of
                5 based on {calculateTotalReviews()} reviews
              </p>
            </div>

            
          </div>
        </div>
      )



        </div>
    );
}

export default CompanyReviews;

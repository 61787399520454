import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import API_BASE_URL from "../config";

function Dashboard() {
  const [reviews, setReviews] = useState([]);
  const [address, setAddress] = useState(null);
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [facebookReviewsInfo, setFacebookReviewsInfo] = useState({
    totalReviews: 0,
    averageRating: 0,
  });

  const userDetails = useSelector((state) => state.auth.user);
  const placeDetails = useSelector((state) => state?.place?.place);
  const facebookDetails = useSelector((state) => state.saveAccessToken);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        if (userDetails && placeDetails) {
          setLoading(true);

          setImg(placeDetails?.image);

          if (placeDetails.address) {
            const shortAddress = placeDetails.address.split(",")[0].trim();
            setAddress(shortAddress);
          }

          if (placeDetails.place_id) {
            const reviewResponse = await fetch(
              `${API_BASE_URL}/get/${placeDetails.place_id}`
            );
            const reviewData = await reviewResponse.json();
            setReviews(reviewData.result.reviews);
          }
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [userDetails, placeDetails]);

  useEffect(() => {
    const fetchFacebookReviewsData = async () => {
      try {
        if (facebookDetails?.accessToken) {
          setLoading(true);

          const reviewData = await fetchFacebookReviews(
            facebookDetails.pageId,
            facebookDetails.accessToken
          );

          setFacebookReviewsInfo(getFacebookReviewsInfo(reviewData));
        }
      } catch (error) {
        console.error("Error fetching Facebook reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFacebookReviewsData();
  }, [facebookDetails, placeDetails]);

  const fetchFacebookReviews = async (pageId, accessToken) => {
    const requestBody = {
      page_id: pageId,
      access_token: accessToken,
    };

    const reviewResponse = await fetch(`${API_BASE_URL}/getFacebookReviews`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    return reviewResponse.json();
  };

  const getFacebookReviewsInfo = (reviewsData) => {
    if (reviewsData && reviewsData.data) {
      const reviews = reviewsData.data;
      const numberOfReviews = reviews.length;
      let totalRating = 0;

      for (const review of reviews) {
        totalRating += review.recommendation_type === "positive" ? 1 : 0;
      }

      const averageRating =
        numberOfReviews > 0 ? (totalRating * 5) / numberOfReviews : 0;

      return { totalReviews: numberOfReviews, averageRating: averageRating };
    }

    return { totalReviews: 0, averageRating: 0 };
  };

  const averageRating =
    reviews.length > 0
      ? reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length
      : 0;

  const renderStars = (rating) => {
    const roundedRating = Math.round(rating);

    const stars = [];
    for (let i = 0; i < roundedRating; i++) {
      stars.push(
        <span key={i} className="star">
          &#9733;
        </span>
      );
    }

    for (let i = 0; i < 5 - roundedRating; i++) {
      stars.push(
        <span key={`empty-${i}`} className="star">
          &#9734;
        </span>
      );
    }

    return stars;
  };

  const calculateTotalReviews = () => reviews.length;

  const getRatingText = (rating) => {
    if (rating >= 4.5) {
      return "Excellent";
    } else if (rating >= 4) {
      return "Very Good";
    } else if (rating >= 3) {
      return "Good";
    } else {
      return "Average";
    }
  };

  const reviewsArray = reviews || [];

  return (
    <div className="container" style={{ overflow: "hidden" }}>
      <div className="mt-5">
        {loading ? (
          <div className="loading-spinner-container">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : reviewsArray.length > 0 || facebookReviewsInfo.totalReviews > 0 ? (
          <>
            {reviewsArray.length > 0 && (
              <>
                <h2>
                  <img src={img} alt="pic" width="40" height="40" />
                  {address}
                </h2>
                <div>
                  <p>
                    {averageRating.toFixed(1)} {getRatingText(averageRating)}{" "}
                    rating
                  </p>
                  <div className="rating-stars">{renderStars(averageRating)}</div>
                  <p>based on {calculateTotalReviews()} reviews</p>
                </div>
              </>
            )}
            {facebookReviewsInfo.totalReviews > 0 && (
              <>
                <h1>Facebook Reviews</h1>
                <p>
                  {facebookReviewsInfo.averageRating.toFixed(1)}{" "}
                  {getRatingText(facebookReviewsInfo.averageRating)} rating
                </p>
                <div className="rating-stars">
                  {renderStars(facebookReviewsInfo.averageRating)}
                </div>
                <p>based on {facebookReviewsInfo.totalReviews} reviews</p>
              </>
            )}
          </>
        ) : (
          <>
            <h2>No Place Selected</h2>
            <p>Click the button below to go to the home page.</p>
            <Link to="/page/platform" className="btn btn-primary mt-3">
              Go to Home
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default Dashboard;

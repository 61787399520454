import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import GooglePlacesAutocomplete from '../pages/AutocompleteComponent';
import ShowReview from '../pages/ShowReview';
import LoginSignup from '../pages/LoginSignup';
import Widget from '../pages/Widget';
import Layout from '../layouts/Layout';
import Home from '../pages/Home';
import Register from '../pages/Register';
import Loader from '../pages/Loader';
import PrivateRoute from '../auth/PrivateRoute';
import API_BASE_URL from '../config';
import { useDispatch } from 'react-redux';
import { logoutUser, setUserDetails } from '../redux/authSlice';
import { setPlaceDetails } from '../redux/placeSlice';
import CreateWidget from '../pages/CreateWidget';
import Dashboard from '../pages/Dashboard';
import WidgetCopy from '../pages/WidgetCopy';
import WidgetEdit from '../pages/WidgetEdit';
import { setLayoutDetails } from '../redux/layoutSlice';
import Addwidget from '../pages/Addwidget';
import Practise from '../practise/Practise';
import FacebookConnect from '../pages/FacebookConnect';
import { setAccessToken } from '../redux/accessTokenSlice';
import Index from '../pages/Index';
import CompanyReviews from '../pages/CompanyReviews';
import RegisterPage from '../pages/RegisterPage';
import { Toaster } from 'react-hot-toast';

const routes = [
  {
    path: '/page',
    element: <Layout />,
    children: [
      { path: 'dashboard', element: <PrivateRoute><Dashboard /></PrivateRoute> },
      { path: 'platform', element: <PrivateRoute><Home /></PrivateRoute> },
      { path: 'platform/Google', element: <PrivateRoute><GooglePlacesAutocomplete /></PrivateRoute> },
      { path: 'platform/Facebook', element: <PrivateRoute><FacebookConnect /></PrivateRoute> },
      { path: 'search/review/:place_id', element: <PrivateRoute><ShowReview /></PrivateRoute> },
      { path: 'widget-customization', element: <PrivateRoute><Widget /></PrivateRoute> },
      { path: 'widget-customization/:widget_id', element: <PrivateRoute><WidgetEdit /></PrivateRoute> },
      { path: 'add-widget', element: <PrivateRoute><Addwidget /></PrivateRoute> },
      { path: 'page-register', element: <PrivateRoute><RegisterPage /></PrivateRoute> },
    ],
  },
  
  { path: '/', element: <Index /> },
  { path: '/login', element: <LoginSignup /> },
  { path: '/register', element: <Register /> },     
  { path: '/loader.js', element: <Loader /> },
  { path: '/create', element: <CreateWidget /> },
  { path: '/widget/:place_id_token', element: <WidgetCopy /> },
  { path: '/practise', element: <Practise /> },
  { path: '/company/:companyName', element: <CompanyReviews /> },
  { path: '/:companyName', element: <CompanyReviews /> },
];


function RouteFile() {
  const dispatch = useDispatch();

  const cookies = new Cookies();
  const token = cookies.get('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) return; // Exit early if token is falsy
  
        const userDataResponse = await fetch(`${API_BASE_URL}/me/${token}`);
        if (!userDataResponse.ok) {
          throw new Error(`Request failed with status ${userDataResponse.status}`);
        }
        const userData = await userDataResponse.json();
  
        if (!userData) {
          dispatch(logoutUser());
          return;
        }
  
        const { user } = userData;
        const userId = user.id;
  
        dispatch(setUserDetails(userData));
  
        const [widgetLayoutResponse, placeResponse] = await Promise.all([
          fetch(`${API_BASE_URL}/getWidgetsWithLayouts/${userId}`),
          fetch(`${API_BASE_URL}/list/${userId}`)
        ]);
  
        const [widgetLayoutData, placeData] = await Promise.all([
          widgetLayoutResponse.json(),
          placeResponse.json()
        ]);
  
        dispatch(setLayoutDetails(widgetLayoutData.data));
  
        placeData.data.forEach(dataItem => {
          if (dataItem.platform === "Facebook") {
            dispatch(setAccessToken({
              accessToken: dataItem.token,
              pageId: dataItem?.place_id,
              address: dataItem?.address
            }));
          } else if (dataItem.platform === "Google") {
            dispatch(setPlaceDetails(dataItem));
          }
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchData();
  }, [token]);
  

  const routeElement = useRoutes(routes);

  return (
    <div>
      {routeElement}
      <Toaster
  position="top-center"
  reverseOrder={false}
/>
    </div>
  );
}

export default RouteFile;

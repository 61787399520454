import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  accessToken: null,
  pageId: null,
  address:null // Add pageId to the initial state
};

// Create slice
const accessTokenSlice = createSlice({
  name: "accessToken",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      const { accessToken, pageId, address } = action.payload;
      console.log('Setting facebook details:', action.payload);
      state.accessToken = accessToken;
      state.pageId = pageId;
      state.address=address;
    },
    clearAccessToken: (state) => {
      console.log('clear fb token');
      state.accessToken = null;
      state.pageId = null;
    },
  },
});

// Export action creators
export const { setAccessToken, clearAccessToken } = accessTokenSlice.actions;

// Export reducer
export default accessTokenSlice.reducer;

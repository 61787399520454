import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../redux/authSlice';
import './css/loginSignup.css';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function LoginSignup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies(['token']);
  const { loading, error } = useSelector((state) => state.auth);

  useEffect(() => {
    const checkAuthentication = () => {
      if (cookies.token) {
        navigate('/page/dashboard');
      }
    };

    checkAuthentication();
  }, [cookies.token, navigate]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    }), 
    onSubmit: async (values) => {
      const { email, password } = values;
      let userCredential = {
        email,
        password,
      };
 
      dispatch(loginUser(userCredential)).then((result) => {
        if (result.payload) {
          formik.resetForm();
          navigate('/page/dashboard');
        }
      });
    },
  });

  const handleRegisterClick = () => {
    navigate('/register');
  };

  const handleBackHome = () =>{
    navigate('/');
  }

  return (
    <div>
      <div className="container">
        <div className="login-form mx-auto">
          <div className="heading text-center">
            <h1>Login</h1>
            <button onClick={handleBackHome}>back to home</button>
          </div>
          <div className="bg-white shadow p-4 mt-5">
            <form onSubmit={formik.handleSubmit} className="was-validated">
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="text"
                  className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  placeholder="Enter email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                  
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                  id="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  placeholder="Enter password"
                  name="password"
                  
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="invalid-feedback">{formik.errors.password}</div>
                )}
              </div>

              <button type="submit" className="btn btn-primary" disabled={loading}>
                {loading ? 'Loading...' : 'Login'}
              </button>
              {error && <div className="alert alert-danger">{error}</div>}

              <button type="button" onClick={handleRegisterClick} className="btn btn-secondary registerButton">
                Register
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginSignup;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviewContent {
  
    max-height: 200px;
    overflow-y: auto;
}

.reviewInner {
    padding: 0 10px;
}

/* 
  @media (max-width: 768px) {
    .slick-list {
      width: 100% !important;
    }
  
    .slick-slide {
      display: flex !important;
      justify-content: center;
    }
  
    .reviewInner {
      width: 100% !important;
      box-sizing: border-box;
      padding: 0 10px;
    }
  }
   */`, "",{"version":3,"sources":["webpack://./src/pages/css/widgetRenderer.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;;;;;;;;;;;;;;;;;IAiBI","sourcesContent":[".reviewContent {\n  \n    max-height: 200px;\n    overflow-y: auto;\n}\n\n.reviewInner {\n    padding: 0 10px;\n}\n\n/* \n  @media (max-width: 768px) {\n    .slick-list {\n      width: 100% !important;\n    }\n  \n    .slick-slide {\n      display: flex !important;\n      justify-content: center;\n    }\n  \n    .reviewInner {\n      width: 100% !important;\n      box-sizing: border-box;\n      padding: 0 10px;\n    }\n  }\n   */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WidgetRenderer from '../components/WidgetRenderer';

function WidgetEdit() {
  const [widgetType, setWidgetType] = useState();
  const {widget_id } = useParams();
  const widgetData = useSelector((state) => state.layout.layout);
  const parsedWidgetId = parseInt(widget_id, 10);

 
console.log(widgetData)
  useEffect(() => {
    if (Array.isArray(widgetData) && widgetData.length > 0) {
      const selectedWidget = widgetData.find((widget) => widget.id === parsedWidgetId);
      if (selectedWidget) {
        const selectedLayoutType = selectedWidget.layout?.type;
        setWidgetType(selectedLayoutType);
      } else {
        setWidgetType(null);
      }
    } else {
      setWidgetType(null);
    }
  }, [widgetData, widget_id]);

  // console.log(widgetType)

  return (
    <div>
      <WidgetRenderer layout={widgetType} />
    </div>
  );
}

export default WidgetEdit;

import React from 'react'
import ConnectedPlateforms from '../components/ConnectedPlateforms'
import List from '../components/list'

function Home() {
  return (
    <div>
        {/* <h1>Welcome to the home page!</h1> */}
        <List/>
        <ConnectedPlateforms/>
    </div>
  )
}

export default Home
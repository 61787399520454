// refreshSlice.js
import { createSlice } from '@reduxjs/toolkit';

const refreshSlice = createSlice({
  name: 'refresh',
  initialState: false,
  reducers: {
    setRefresh: (state, action) => action.payload,
  },
});

export const { setRefresh } = refreshSlice.actions;
export default refreshSlice.reducer;

import React, { useRef } from 'react'

function Practise() {
    const ref = useRef(0);
    
    const handleClick = () =>{
        const current = ref.current+1;
        alert('you clicked ' + current + ' times')
        ref.current++;
    }
    
  return (
    <div>
        <button onClick={handleClick}>click me</button>
    </div>
  )
}

export default Practise
import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../config";

import { useDispatch } from "react-redux";
import { logoutUser } from "../redux/authSlice";

function Navbar() {
  const removeCookie = new Cookies();

  const [user, setUser] = useState(null);
  const userToken = new Cookies().get('token');
    const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        // const userToken = await getToken();
      
        setToken(userToken);

        if (userToken) {
          // Fetch user information here using a function that returns a promise (e.g., getUserInfo())
          try {
            const tokenUser = await fetch(
              `${API_BASE_URL}/me/${userToken}`
            );
            const responseText = await tokenUser.text(); // Get the response as text

            try {
              const responseData = JSON.parse(responseText); // Try parsing as JSON
              setUser(responseData.user?.name);
            } catch (jsonError) {
              console.error("Error parsing JSON:", jsonError);
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        }
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    fetchToken();
  }, [userToken], [navigate]); // Include getToken as a dependency

  
  const handleLogout = () => {
    // console.log('logout hit');
    
    // Dispatch action to log out user
    dispatch(logoutUser());
    
    // Optionally, you can redirect the user to the login page or perform any other actions after logout
    navigate('/login');
  };
  return (
    <div>
      <div id="navbar">
        <nav className="navbar">
          <div className="container">
            {user && (
              <span id="currentUser">Recommendas</span>
            )}
            <div className="">
              {user && (
                <button id="logoutBtn" className="btn text-capitalize bg-secondary text-light" onClick={handleLogout}>
                  Logout
                </button>
              )}
            </div>
          </div>
        </nav>
      </div>
    
    </div>
  );
}

export default Navbar;

import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import API_BASE_URL, { APP_BASE_URL } from "../config";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import googleIcon from "../assets/googleIcon/icon.svg";
import "../pages/css/widgetRenderer.css";
import "../pages/css/widget2.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Spinner } from "react-bootstrap";

const WidgetRenderer = ({ layout }) => {
  const [place_id, setPlaceId] = useState();
  const { widget_id } = useParams();
  const [reviewsData, setReviewsData] = useState({});
  const [message, setMessage] = useState("");
  const [encodedToken, setEncodedToken] = useState();
  const placeData = useSelector((state) => state.place.place);
  const [loading, setLoading] = useState(false);
  const [selectedStars, setSelectedStars] = useState([]);
  const facebookDetails = useSelector((state) => state.saveAccessToken);
  const [facebookToken, setFacebookToken] = useState();
  const [facebookReviewsInfo, setFacebookReviewsInfo] = useState({});
  
// console.log(facebookDetails)
// Add a state to track whether Facebook reviews are loaded
const [facebookReviewsLoaded, setFacebookReviewsLoaded] = useState(false);

  const userDetails = useSelector((state) => state.auth.user);
  const user_id = userDetails?.user?.id || userDetails?.data?.user?.id;

  const layoutDate = useSelector((state) => state.layout.layout);

  const pageId = facebookDetails?.pageId;

  useEffect(() => {
    const fetchToken = async () => {
      const place_id = facebookDetails?.pageId;

      const tokenData = await fetch(
        `${API_BASE_URL}/getToken/${place_id}/${widget_id}`
      );
      const jsonTokenData = await tokenData.json();
      setFacebookToken(jsonTokenData.encodedToken);
      
    };
    fetchToken();
  }, [pageId, widget_id]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        if (facebookDetails?.accessToken) {
          setFacebookReviewsLoaded(true);
         
          const reviewData = await fetchFacebookReviews(
            facebookDetails.pageId,
            facebookDetails.accessToken
          );
          setFacebookReviewsInfo(reviewData);
          setFacebookReviewsLoaded(false);
        }
      } catch (error) {
        setFacebookReviewsLoaded(false);
        console.error("Error fetching reviews:", error);
      }
    };

    if (pageId) {
      fetchReviews();
    }
  }, [pageId, widget_id]);

  const fetchFacebookReviews = async (pageId, accessToken) => {
    const requestBody = {
      page_id: pageId,
      access_token: accessToken,
    };

    const reviewResponse = await fetch(
      `${API_BASE_URL}/getFacebookReviews`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );

    return reviewResponse.json();
  };

  useEffect(() => {
    const getOriginalPlaceIdFromToken = async () => {
      try {
        setPlaceId(placeData?.place_id);
      } catch (error) {
        console.error("Error fetching original place ID:", error);
      }
    };

    getOriginalPlaceIdFromToken();
  }, [placeData]);

  useEffect(() => {
    const fetchToken = async () => {
      const place_id = placeData?.place_id;

      const tokenData = await fetch(
        `${API_BASE_URL}/getToken/${place_id}/${widget_id}`
      );
      const jsonTokenData = await tokenData.json();
      setEncodedToken(jsonTokenData.encodedToken);
    };
    fetchToken();
  }, [place_id, widget_id]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const matchingLayout = layoutDate.find(
          (layout) =>
            layout.id === parseInt(widget_id) && layout.source === "all"
        );

        if (matchingLayout) {
          setLoading(true);
          const reviewResponse = await fetch(
            `${API_BASE_URL}/getReviewsAll/${user_id}`
          );
          const reviewData = await reviewResponse.json();
          setReviewsData(reviewData);
          setLoading(false);
        } else {
          const reviewResponse = await fetch(
            `${API_BASE_URL}/get/${place_id}`
          );
          const reviewData = await reviewResponse.json();
          setReviewsData(reviewData);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching reviews:", error);
      }
    };

    if (place_id) {
      fetchReviews();
    }
  }, [place_id, widget_id, layoutDate]);

  let reviews = [];

  // console.log(facebookReviewsInfo)

  if (reviewsData.result && reviewsData.result.reviews) {
    reviews = reviewsData.result.reviews;
  } else if (
    facebookReviewsInfo
    
  ) {
    const facebookReviews = facebookReviewsInfo?.data
      ? facebookReviewsInfo?.data
      : [];
    const googleReviews = reviewsData.Google ? reviewsData.Google : [];

    reviews = [...facebookReviews, ...googleReviews];
  }

 

  const averageRating = useMemo(() => {
    if (reviews?.length > 0) {
      const totalRating = reviews.reduce((sum, review) => {
        if ("rating" in review) {
          return sum + review.rating;
        } else if (review.hasOwnProperty("rating")) {
          return sum;
        } else {
          return sum + 5;
        }
      }, 0);
      return totalRating / reviews.length;
    }
    return 0;
  }, [reviews]);

  const handleWidget = () => {
    var buttonIframe;

    if (layout === "button") {
      buttonIframe = `<iframe src="${APP_BASE_URL}/widget/${encodedToken}" width='400px'></iframe>`;
    }

    if (layout === "slider") {
      buttonIframe = `<iframe src="${APP_BASE_URL}/widget/${encodedToken}" height='650px' width='100%'></iframe>`;
    }

    if (layout === "singlePage") {
      buttonIframe = `<iframe src="${APP_BASE_URL}/widget/${encodedToken}" height='650px' width='100%'></iframe>`;
    }

    const tempInput = document.createElement("input");
    tempInput.value = buttonIframe;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");

    document.body.removeChild(tempInput);

    setMessage("Copied to clipboard");
    setTimeout(() => {
      setMessage("");
    }, 1000);
    
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleString("en-US", options);
  };

  const renderStars = (rating) => {
    const roundedRating = Math.round(rating);
    const stars = [];
    for (let i = 0; i < roundedRating; i++) {
      stars.push(
        <span key={i} className="star">
          &#9733;
        </span>
      );
    }

    for (let i = 0; i < 5 - roundedRating; i++) {
      stars.push(
        <span key={`empty-${i}`} className="star">
          &#9734;
        </span>
      );
    }

    return stars;
  };

  const calculateTotalReviews = () => {
    return reviews?.length;
  };

  const getRatingText = (rating) => {
    if (rating >= 4.5) {
      return "Excellent";
    } else if (rating >= 4) {
      return "Very Good";
    } else if (rating >= 3) {
      return "Good";
    } else {
      return "Average";
    }
  };

  const handleCheckboxChange = (star) => {
    const updatedSelectedStars = selectedStars.includes(star)
      ? selectedStars.filter((selectedStar) => selectedStar !== star)
      : [...selectedStars, star];

    setSelectedStars(updatedSelectedStars);
  };

  const filteredReviews = (reviews || []).filter((review) =>
    selectedStars?.length === 0
      ? true
      : selectedStars.includes(review.rating)
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


 

  if (loading || facebookReviewsLoaded) {
    return (
      <div className="container loading-spinner-container">
        <Spinner />
      </div>
    );
  }


  // console.log(reviews)
  
  if (layout === "button") {
    return (
      <div className="container" style={{ overflow: "hidden" }}>
        <div className="mt-5">
          <h2>Reviews</h2>
          <div className="review-stats">
            <p>{getRatingText(averageRating)}</p>
            <div className="rating-stars">{renderStars(averageRating)}</div>
            <p>
              {reviews?.length} <strong>reviews on </strong>
            </p>
            {reviews[0]?.recommendation_type? (
            <div>All plateforms</div>
          ):(
            <img
              src={googleIcon}
              alt="Google Icon"
              className="google-icon"
              height="20px"
            />
          )}
          </div>
          <button onClick={handleWidget} className="btn btn-primary mt-3">
            Make a widget
          </button>
          {message && <div className="alert alert-success mt-3">{message}</div>}
        </div>
      </div>
    );
  } else if (layout === "slider") {
    return (
      <div className="container" style={{ overflow: "hidden" }}>
        <div className="mt-5">
          <h2>Reviews</h2>
          <Slider {...sliderSettings}>
            {reviews?.map((review, index) => (
              <div key={index} className="review-slide reviewInner">
                <div className="review-header">
                  <h3>{review?.author_name}</h3>
                  {review.recommendation_type ? (
                  <p>{review.recommendation_type}</p>
                ) : (
                  <img
                    src={review?.profile_photo_url || ""}
                    alt={`Profile Photo of ${review.author_name}`}
                    className="user-photo"
                    loading="lazy"
                  />
                )}
                </div>
                <p className="review-date">
                  <strong>Posted on:</strong>{" "}
                  {formatTimestamp(review.created_time ? review.created_time : review.time)}
                </p>
                <div className="rating-stars">{renderStars(review.rating)}</div>

                {review.recommendation_type?(
                   <i className="fab fa-facebook facebook-icon"></i>
                ):(
                  <img
                  src={googleIcon}
                  alt="Google Icon"
                  className="google-icon"
                  height="20px"
                />
                )}

                <div className="reviewContent">
                  <p>{review.text ? review.text : review.review_text}</p>
                </div>
              </div>
            ))}
          </Slider>
          <div className="mt-3">
            <p>
              <strong>Average Rating:</strong> {averageRating?.toFixed(1)} of 5
              based on {calculateTotalReviews()} reviews
            </p>
          </div>
          <button onClick={handleWidget} className="btn btn-primary mt-3">
            Make a widget
          </button>
          {message && <div className="alert alert-success mt-3">{message}</div>}
        </div>
      </div>
    );
  } else if (layout === "singlePage") {
    return (
      <div className="container" style={{ overflow: "hidden" }}>
        <div className="mt-5">
          <h2>Reviews</h2>
          <div>
            {[1, 2, 3, 4, 5].map((star) => (
              <label key={star} className="star-checkbox-label">
                <input
                  type="checkbox"
                  value={star}
                  checked={selectedStars.includes(star)}
                  onChange={() => handleCheckboxChange(star)}
                />
                {star} Star
              </label>
            ))}
          </div>
          {filteredReviews.map((review, index) => (
            <div key={index} className="reviewInner">
              <div className="review-header">
                <h3>{review?.author_name}</h3>
                {review.recommendation_type ? (
                  <p>{review.recommendation_type}</p>
                ) : (
                  <img
                    src={review?.profile_photo_url || ""}
                    alt={`Profile Photo of ${review.author_name}`}
                    className="user-photo"
                    loading="lazy"
                  />
                )}
              </div>
              <p className="review-date">
                <strong>Posted on:</strong>{" "}
                {formatTimestamp(review.created_time ? review.created_time : review.time)}
              </p>
              <div className="rating-stars">{renderStars(review.rating)}</div>
              {review.recommendation_type?(
                   <i className="fab fa-facebook facebook-icon"></i>
                ):(
                  <img
                  src={googleIcon}
                  alt="Google Icon"
                  className="google-icon"
                  height="20px"
                />
                )}
              <div className="reviewContent">
                <p>{review.text ? review.text : review.review_text}</p>
              </div>
            </div>
          ))}
          <div className="mt-3">
            <p>
              <strong>Average Rating:</strong> {averageRating?.toFixed(1)} of 5
              based on {calculateTotalReviews()} reviews
            </p>
          </div>
          <button onClick={handleWidget} className="btn btn-primary mt-3">
            Make a widget
          </button>
          {message && <div className="alert alert-success mt-3">{message}</div>}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h3>Unknown Layout</h3>
      </div>
    );
  }
};

export default WidgetRenderer;

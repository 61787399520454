import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar() {
  return (
    <div className="sidebar">
      {/* <h2>Sidebar</h2> */}
      <ul>
        <li><Link to='dashboard'>Dashboard</Link></li>
        <li><Link to='platform'>Connected Plateform</Link></li>
        <li><Link to='widget-customization'>Widget Customization</Link></li>
        <li><Link to='page-register'>Page Register</Link></li>
        {/* <li><Link to='/'>Home</Link></li> */}
        
      </ul>
    </div>
  );
}

export default Sidebar;

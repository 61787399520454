import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Cookies } from "react-cookie";
import API_BASE_URL from "../config";

// Action to log in user
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userCredentials) => {
    const cookies = new Cookies();

    try {
      const response = await axios.post(
        `${API_BASE_URL}/login`,
        userCredentials
      );

      const authToken = response.data.access_token;
      const expirationTimeInSeconds = response.data.expires_in;

      cookies.set("token", authToken, {
        maxAge: expirationTimeInSeconds,
        HttpOnly: true,
        secure: true,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const logoutUser = () => async (dispatch) => {
  const cookies = new Cookies();
  cookies.remove("token");

  dispatch(clearUserData());
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },
  reducers: {
    setUserDetails: (state, action) => {
      console.log("Setting user details:", action.payload);
      state.user = action.payload;
    },
    clearUserData: (state) => {
      state.loading = false;
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        if (action.error.message === "Request failed with status code 401") {
          state.error = "Access Denied! Invalid Credentials";
        } else {
          state.error = action.error.message;
        }
      });
  },
});

export const { setUserDetails, clearUserData } = userSlice.actions;

export default userSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-slider .slick-next {
    right: 0;
    z-index: 1;
 
}

.slick-slider .slick-prev {
    left: 0;
    z-index: 1;

}

.slick-slider .slick-prev:before, .slick-slider .slick-next:before{
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/pages/css/widget2.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,UAAU;;AAEd;;AAEA;IACI,OAAO;IACP,UAAU;;AAEd;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".slick-slider .slick-next {\n    right: 0;\n    z-index: 1;\n \n}\n\n.slick-slider .slick-prev {\n    left: 0;\n    z-index: 1;\n\n}\n\n.slick-slider .slick-prev:before, .slick-slider .slick-next:before{\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import API_BASE_URL from "../config";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutDetails } from "../redux/layoutSlice";

function AddWidget() {
  const navigate = useNavigate();
  const [source, setSource]=useState();
  const [selectedLayout, setSelectedLayout] = useState("");
  const [layoutList, setLayoutList] = useState([]);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.auth.user);
  const place= useSelector((state)=>state.place.place);
  const facebookToken = useSelector((state)=> state.saveAccessToken.accessToken);
  // console.log(facebookToken)
  const id = userData?.user?.id || userData?.data?.user?.id;
const [fetchbool, setfetch] = useState(false);
  // console.log(id)

  useEffect(() => {
    const fetchLayoutData = async () => {
      try {
        const layoutListData = await fetch(`${API_BASE_URL}/getLayouts`);
        const layoutListDataJson = await layoutListData.json();
        setLayoutList(layoutListDataJson.data);
      } catch (error) {
        console.error("Error fetching layout data:", error);
      }
    };
    

    fetchLayoutData();
  }, [fetchbool]); // Add selectedLayout as a dependency

  console.log(selectedLayout)
  const handleLayoutSubmit = async () => {
    setfetch(true);
    // Implement logic to handle the selected layout
      // alert(source)
    const saveWidget = await fetch(
      `${API_BASE_URL}/saveWidgets/${selectedLayout}/${id}/${source}`
    );
    const res = await saveWidget.json();
    console.log(res);
    dispatch(setLayoutDetails(res.data));
    navigate("/page/widget-customization");

    // console.log(`Selected Layout: ${selectedLayout}`);
    // Add additional logic or API calls as needed
  };

  return (
    <div className="container">
  <h2>Source</h2>
  <div>
    {place?.platform === "Google" ? (
      <label>
        <input
          type="radio"
          value="google"
          name="source"
          checked={source === "Google"}
          onChange={() => setSource("Google")}
         
        />
        Google
      </label>
    ) : null}

    {facebookToken? (
      
    <label>
    <input
      type="radio"
      value="all"
      name="source"
      checked={source === "all"}
      onChange={() => setSource("all")}
    />
    All
  </label>

    ):null}

  </div>

  <h2>Layout</h2>
  {layoutList.map((layout) => (
    <div key={layout.id}>
      <label>
        <input
          type="radio"
          value={layout.id}
          name="layout"
          checked={selectedLayout === layout.id}
          onChange={() => setSelectedLayout(layout.id)}
        />
        {layout.type}
      </label>
    </div>
  ))}

  <button className="btn btn-primary" onClick={handleLayoutSubmit}>
    Create
  </button>
</div>

  );
}

export default AddWidget;

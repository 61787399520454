import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import API_BASE_URL from '../config';

function PrivateRoute({children}) {
  const [user,setUser] = useState();
    const token = new Cookies().get('token');
    useEffect(()=>{
      const fetchUserDetails = async()=>{
        const get = await fetch(`${API_BASE_URL}/respondWithToken/${token}`);
        const getJson = await get.json();
        setUser(getJson.user)
      }
      fetchUserDetails();
    },[token])

    if(!token && !user){
        return <Navigate to="/login" replace={true}/>
    }
  return children;
}

export default PrivateRoute
import { createSlice } from "@reduxjs/toolkit";

const placeSlice = createSlice({
  name: "place",
  initialState: {
    place: null,
    error: null,
  },
  reducers: {
    setPlaceDetails: (state, action) => {
      console.log("Setting place details:", action.payload);
      state.place = action.payload;
    },
    clearPlace: (state) => {
      state.place = null;
    },
  },
});

export const { setPlaceDetails, clearPlace } = placeSlice.actions;

export default placeSlice.reducer;

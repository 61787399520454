import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ConnectedPlateforms() {
  const navigate = useNavigate();
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([
    "Google",
    "Facebook",
    "Instagram",
  ]);

  // console.log(disabledButtons)
  const facebookTokenCheck = useSelector((state)=>state.saveAccessToken.accessToken)

  
  const userData = useSelector((state) => state.auth.user);
  const token = userData?.access_token || userData?.data?.access_token;
  const placeData = useSelector((state) => state.place.place);

  const fetchData = useCallback(async () => {
    if (token && placeData) {
      try {
        const { platform, place_id } = placeData;
        const disabledPlatforms = ["Google", "Instagram"]; // Removed "Facebook" from here
  
        // Check if the Facebook token exists and add "Facebook" to the disabled platforms
        
        if (platform && (disabledPlatforms.includes(platform) || place_id)) {
          const platformsArray = platform?.split(',').map(platform => platform.trim()) || [];
          setDisabledButtons(platformsArray);
        } else {
          setDisabledButtons([]);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      setDisabledButtons([]); // Set empty array if there is no placeData
    }
  }, [placeData, token, facebookTokenCheck]); // Added facebookTokenCheck as a dependency
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Filter the items based on the search term
    const filtered = ["Google", "Facebook", "Instagram"].filter((item) =>
      item.toLowerCase().includes(term.toLowerCase())
    );
    
    setFilteredItems(filtered);

  };

  const handleAdd = async (item) => {
    console.log("Navigating to:", `/search/${item}`);
    navigate(`${item}`);
  };

  if (facebookTokenCheck) {
          
    disabledButtons.push("Facebook");
    
  }



  return (
    <div>
      <div className="container">
        {/* <div><button className="btn btn-secondary" onClick={handleCreateWidget}>Create a Widget</button></div> */}
        <div className="mt-5">
          <h2>Connect platforms</h2>

          {/* Search bar using Bootstrap */}
          <div className="mt-5 bg-white gap-2 shadow-sm p-4">
            <div className="border-bottom">
              <h4>Search</h4>
            </div>
            <Form className="w-100 mt-4">
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2"
                value={searchTerm}
                onChange={handleSearch}
              />
            </Form>
          </div>

          {/* List of items */}
          <div className="bg-white shadow-sm w-50 p-4 mt-5">
            <div className="border-bottom">
              <h2>Review platforms</h2>
            </div>
            <div>
              <h5 className="mt-4">Most popular</h5>
            </div>
           <ListGroup className="mt-4">
            {filteredItems.map((item, index) => (
              <ListGroup.Item
                key={index}
                className="mb-4 d-flex justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center">
                  <div>{item}</div>
                </div>
                <Button
                  className="btn"
                  onClick={() => handleAdd(item)}
                  disabled={disabledButtons?.includes(item)}
                >
                  {disabledButtons?.includes(item) ? "Already Added" : "Add"}
                  {/* {disabledButtons?.includes(item) ? "Already Added" : "Add"} */}
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectedPlateforms;

import axios from "axios";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { registerUser } from "../redux/register";
import { useDispatch, useSelector } from "react-redux";

function Register() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token"]);
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.register);

  const handleRegisterClick = (e) => {
    e.preventDefault();
    const userCredential = {
      name,
      email,
      password,
    };
    dispatch(registerUser(userCredential)).then((result) => {
      if (result.payload) {
        setName("");
        setEmail("");
        setEmail("");
        navigate("/");
      }
    });
    console.log("Logging in with:", name, email, password);
  };

  return (
    <div>
      <div className="container">
        <div className="register-form mx-auto">
          <div className="heading text-center">
            <h1>Register</h1>
          </div>
          <div className="bg-white shadow p-4 mt-5">
            <form className="was-validated">
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  name="password"
                  required
                />
              </div>

              <button
                type="button"
                onClick={handleRegisterClick}
                className="btn btn-primary"
              >
                {loading ? "Loading..." : "Register"}
              </button>
              {error && <div className="alert alert-danger">{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
